<template>
  <LiefengContent>
    <template v-slot:title>批次统计</template>
    <template v-slot:contentArea>
      <!--文字部分-->
      <Card>
        <Row style="padding:20px">
          <Col span="5" style="border:double #FFFAFA">
            <Card>
              <template #title>批次状态</template>
              <p><b>推送状态: </b><span v-text="pushStatus"></span></p>
              <p><b>归档状态: </b><span v-text="hisStatus"></span></p>
              <p><b>数据包状态: </b><span v-text="packageStatus"></span></p>
              <p><b>访问量: </b><span v-text="detail.batchViewCount"></span></p>
            </Card>
          </Col>
          <Col span="5" offset="1">
            <Card shadow style="border:double #FFFAFA">
              <template #title>推送数量</template>
              <p><b>消息总数: </b><span v-text="detail.messageSize"></span></p>
              <p><b>已推送: </b><span v-text="detail.pushedNum"></span></p>
              <p><b>未推送: </b><span v-text="unpushedNum"></span></p>
              <p><b>失败数: </b><span v-text="detail.failPushedNum"></span></p>
            </Card>
          </Col>
          <Col span="5" offset="1">
            <Card shadow style="border:double #FFFAFA">
              <template #title>数据包状态</template>
              <p><b>总数: </b><span v-text="detail.packageNum"></span></p>
              <p><b>成功: </b><span v-text="successPackageNum"></span></p>
              <p><b>进行中: </b><span v-text="ingPackageNum"></span></p>
              <p><b>异常: </b><span v-text="throwPackageNum"></span></p>
            </Card>
          </Col>
          <Col span="5" offset="1" style="border:double #FFFAFA">
            <Card shadow>
              <template #title>耗时</template>
              <p><b>开始时间: </b><span v-text="detail.startTime"></span></p>
              <p><b>结束时间: </b><span v-text="detail.endTime"></span></p>
              <p><b>总耗时: </b><span v-text="detail.timesTotal"></span></p>
              <p><b>平均耗时: </b><span v-text="detail.timesAvg"></span></p>
            </Card>
          </Col>
        </Row>
      </Card>
      <Card class="protarit-div">
        <div class="portrait-title">{{ '消息分析' }}</div>
        <div class="pie-image">
          <div class="pie-content">
            <div class="image" v-if="messageData.rows">
              <VeRing :data="messageData"></VeRing>
            </div>
            <div class="image">
              <VeRing :data="batchData"></VeRing>
            </div>
            <div class="table">
              <Table :columns="packageColumn" :data="detail.packageVos"></Table>
            </div>
          </div>
        </div>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import VeRing from "v-charts/lib/ring.common";

export default {
  data() {
    return {
      detail: {},
      messageData: {
        columns: ["类型", "总数"],
        rows: [

        ]
      },
      batchData: {
        columns: ["类型", "总数"],
        rows: [
          {类型: "成功数据包", 总数: ""},
          {类型: "进行中数据包", 总数: ""},
          {类型: "异常数据包", 总数: ""}
        ]
      },
      packageColumn: [
        {title: "数据包ID", key: "packageId", align: "center"},
        {title: "数据包数量", key: "packageNum", align: "center"},
        {
          title: "状态", key: "pushStatus", align: "center", render: (h, params) => {
            let pushStatus = params.row.pushStatus;
            var pushDetail = "";
            if (pushStatus == 1) {
              pushDetail ='待下发';
            }
            if (pushStatus == 2) {
              pushDetail ='推送中';
            }
            if (pushStatus == 3) {
              pushDetail ='已推送';
            }
            if (pushStatus == 4) {
              pushDetail ='取消推送';
            }
            if (pushStatus == 5) {
              pushDetail ='中断';
            }
            if (pushStatus == 6) {
              pushDetail ='异常丢失';
            }
            return h('div', [
              h('span', {},
                  pushDetail
              )
            ]);
          }
        },
        {title: "耗时", key: "times", align: "center"},
        {title: "创建时间", key: "gmtCreate", align: "center",
          render:(h,params)=> {
            return h(
                'div',
                {},
                params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd hh:mm:ss'):''
            )
          }}
      ],
      pushStatus: '进行中',
      hisStatus: '未归档',
      packageStatus: '异常',
      unpushedNum: 0,
      successPackageNum: 0,
      ingPackageNum: 0,
      throwPackageNum: 0,
    };
  },
  methods: {
    formatterData(value) {
      var date = new Date();
      var year = "";
      var month = "";
      var day = "";
      if (value == 1) {
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
      } else if (value == 7 || value == 30) {
        let dates = new Date(date.getTime() - value * 24 * 3600 * 1000);
        year = dates.getFullYear();
        month = dates.getMonth() + 1;
        day = dates.getDate();
      }

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowTime = year + "-" + month + "-" + day;
      return nowTime;
    },
    //接口部分

    // 各机构当前栏目统计
    getBatchDetail() {
      this.$get("/verifyapi/api/message/batch/selectBatchDetailById", {
        batchId: this.$route.query.batchId
      }).then(res => {
        let data = res.data;
        console.log(data)
        if (res.code != 200) {
          this.$Message.error({
            background: true,
            content: res.desc,
          })
        } else {
          this.detail = data;
          this.cal(this.detail)
        }
      })
    },
    cal(data) {
      this.unpushedNum = this.detail.messageSize - this.detail.pushedNum;
      if (data.pushStatus == 2) {
        this.pushStatus = '已推送'
      }
      if (data.pushStatus == 1) {
        this.pushStatus = '未推送'
      }
      if (data.hisStatus == 2) {
        this.hisStatus = '已归档'
      }
      if (data.hisStatus == 1) {
        this.hisStatus = '未归档'
      }
      if (data.pushStatus == 0) {
        this.pushStatus = '进行中'
      }
      this.packageStatus = '正常';
      var successPackageNum = 0;
      var throwPackageNum = 0;
      var ingPackageNum = 0;
      data.packageVos.forEach(p => {
        if (p.pushStatus != 1 && p.pushStatus != 2 && p.pushStatus != 3) {
          this.packageStatus = '异常';
          throwPackageNum += 1;
        }
        if (p.pushStatus == 3) {
          successPackageNum += 1;
        }
        if (p.pushStatus == 2) {
          ingPackageNum += 1;
        }
      });
      this.successPackageNum = successPackageNum;
      this.throwPackageNum = throwPackageNum;
      this.ingPackageNum = ingPackageNum;
      if (successPackageNum == data.packageVos.length) {
        this.packageStatus = '已完成'
      }
      if (data.timesTotal == 0) {
        this.detail.timesTotal = '暂无';
      }
      if (data.timesAvg == 0) {
        this.detail.timesAvg = '暂无'
      }
      if (data.endTime == null) {
        this.detail.endTime = '暂无'
      }

      this.batchData.rows[0].总数 = successPackageNum;
      this.batchData.rows[1].总数 = ingPackageNum;
      this.batchData.rows[2].总数 = throwPackageNum;
      this.batchData.rows.map(item => {
        if (item.总数 == 0) {
          item.总数 = null;
        }
      });

      this.messageData.rows = data.analyzeFail;
    }
  },
  created() {
    this.getBatchDetail();
  },
  components: {
    LiefengContent,
    VeRing
  }
};
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none !important;
}

.title-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .title-left {
    width: 80%;
    display: flex;
    flex-flow: wrap;

    .item-num {
      width: 150px;
      height: 150px;
      margin-right: 10px;
      text-align: center;

      .num {
        padding-top: 50px;
        padding-bottom: 40px;
        font-size: 30px;
        font-weight: bold;
      }

      .title {
        font-size: 16px;
      }
    }
  }

  .title-center {
    width: 10%;

    .item-num {
      margin: 0 auto;
      width: 150px;
      height: 150px;
      margin-right: 10px;
      text-align: center;

      .num-red {
        font-size: 30px;
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: bold;
        color: red;
      }

      .title {
        font-size: 16px;
      }
    }
  }

  .title-right {
    width: 20%;

    .item-num {
      margin: 0 auto;
      width: 100px;
      height: 200px;
      margin-right: 10px;
      text-align: center;

      .num-weight {
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: bold;
        font-size: 40px;
      }

      .title {
        font-size: 16px;
      }
    }
  }
}

.protarit-div {
  width: 100%;
  margin-top: 20px;

  .portrait-title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    border-bottom: 1px solid #ccc;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  .pie-image {
    border-bottom: 1px solid #ccc;
    font-size: 16px;

    .title-padding {
      padding: 10px 0;
      font-weight: bold;
      font-size: 16px;
    }

    .pie-content {
      display: flex;
      justify-content: space-between;

      .image {
        width: 30%;
      }

      .table {
        width: 40%;
      }
    }
  }

  .people-num {
    .title-padding {
      font-weight: bold;
      font-size: 16px;
      padding: 10px 0;
    }
  }

  .link-div {
    width: 100%;
    height: 400px;
    padding: 10px 0;

    .select-div {
      display: flex;
      justify-content: flex-start;
    }
  }

  .protrait-table {
    padding: 10px 0;
  }

  // 饼图css
  .jus-div {
    width: 100%;
  }
}

.content {
  margin-top: 120px;

  .link-div {
    .select-div {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.table-div {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .table-left,
  .table-right {
    width: 49.5%;
    min-height: 699px;
    overflow: scroll;

    .update-time {
      margin-bottom: 5px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      text-align: center;

      .table-title {
        padding-left: 53px;
        font-weight: bold;
      }

      .table-btn {
        color: #169bd5;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>